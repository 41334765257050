.column {
    flex: 1;
}

main {
    .block {
        display: flex;
    }

    .block.introduction {
        margin-top: 6vh;
        min-height: calc(100vh - 20vh);
    }

    .column.left {
        text-align: end;
    }

    .column.right {
        h1 {
            margin-left: -10px; /* Correction for margin of leftmost character. */
            margin-bottom: 0.4em;

        }

        h2 {
            margin-left: -4px; /* Correction for margin of leftmost character. */
            margin-top: -10%;
        }
        p {
            margin-top: -5%;
        }

        .links {
            margin-top: 2.5rem;
            font-size: 1.5rem;

            a {
                margin-right: 0.5rem;
                text-decoration: none;
            }
        }

    }

    @media screen and (max-width: $break-large) {
        .block {
            flex-direction: column;
        }

        .block.introduction {
            margin-top: 0;
        }

        .column.left {
            text-align: center;
        }

        .column.right h1 {
            margin-top: 0;
        }

        img.portrait {
            margin: 0;
        }
    }
}

footer {
    display: flex;
    margin-top: 3rem;
    font-size: 0.75rem;

    @media screen and (max-width: $break-large) {
        flex-direction: column;
    }
}
